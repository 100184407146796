export default defineNuxtRouteMiddleware(async (to) => {
  const { backendUrl, frontendUrl } = useRuntimeConfig().public;
  const uriSegments = to.path.split("/").filter(Boolean);

  if (uriSegments[0] === "share" && uriSegments[1] && uriSegments[2].includes("-")) {
    const collectionHashid = afterLast(uriSegments[2], "-");

    const { redirect_to } = await $larafetch<{ redirect_to: string }>(`${backendUrl}/share/${uriSegments[1]}/${collectionHashid}`, {
      query: {
        email: to.query.email,
      },
    });

    if (redirect_to) {
      return navigateTo(redirect_to, { replace: true });
    }
  }
});
